<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p(v-html="exp.expDesc")
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import BackHelp from "../../../components/BackHelp";
import Footer from "../../../components/TheFooter";
export default {
  name: "ShareWebsite",
  components: { Footer, BackHelp, Navbar },
  data() {
    return {
      ContactList: [
        {
          title: "3.4 How to share website to your contacts",
          description: [
            {
              text: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
            {
              text: "2. Select “Share website” and choose the medium that you would like to share across",
              imgType: true,
              img: "351. Manage website – share.png",
            },
          ],
          explanation: [
            {
              expTitle: "Message: ",
              expDesc:
                "1. Select contact or click “Select all” " +
                "<br/> 2.  Click “Next” </br> " +
                "3. Tap on the message to edit the content </br>" +
                "4. Sending via message</br> " +
                "\u00A0\u00A0 a) Click “Bulk message” in order to send to all selected contacts at once</br>" +
                "\u00A0\u00A0 b) Click “Send” in order to send in one by one",
              imgType: true,
              img: "352. Share message.png",
            },
            {
              expTitle: "WhatsApp:",
              expDesc:
                "1. Select contact or click “Select all” " +
                "<br/> 2.  Click “Next” </br> " +
                "3. Tap on the message to edit the content if you want </br>" +
                "4. Click “Send” in order to send one by one\n</br> ",
              imgType: true,
              img: "352. Share message – 2.png",
            },
            {
              expTitle: "Facebook:",
              expDesc:
                "1. It will lead you to your Facebook page " +
                "<br/> 2. Tap on “Say something about this…” for adding extra message </br> " +
                "3. Select : News Feed, Your Story or both </br>" +
                "4. Click “Post” once it complete</br> ",
              imgType: true,
              img: "Group 18332.jpg",
            },
            {
              expTitle: "Copy link:",
              expDesc: "1. Choose the contact that you would like to share",
              imgType: false,
            },
          ],
        },
      ],
    };
  },
};
</script>
