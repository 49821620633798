<template lang="pug">
.btn.btn-outline-dark(@click="goBack")
  | Back
</template>

<script>
export default {
  name: "BackHelp",
  components: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>
